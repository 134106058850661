import { Box } from '@chakra-ui/react';

import { signIn } from 'next-auth/client';

import Page from '@inspire/ui/chakra/layout/Page';
import Panel from '@inspire/ui/general/Panel';
import { OnlyLoggingOngoing } from 'components/auth/Redirects';
import Layout from 'components/layout/Layout';
import { useRouter } from 'next/router';
import { LOGGED_IN_HOME_PATH, CONNEXION_PATH } from 'lib/paths';
import { useMemoryStore } from 'lib/store';
import { accessSSO } from 'lib/sso';
import { wait } from '@v1v2/util';
import { wrongCredentialsToast, didntWorkToast } from 'lib/toasts';
import { useEffect } from 'react';
import { useUser } from 'lib/hooks';
import TagManager from 'lib/packages/react-gtm-module/TagManager';
import { GTM_ACTION_CONNEXION_SUCCES, GTM_CATEGORY_COMPTE } from 'lib/gtm';
import { getCookie } from 'lib/cookies';

let userId = '';
let userSsoId = '';
let profil = 'guest';
let visitorId = '';

const ConnexionPage = () => {
  const router = useRouter();
  const lyceenSignupClasse = useMemoryStore((s) => s.lyceenSignupClasse);
  const signupToken = lyceenSignupClasse?.signupToken;
  const redirectTargetUrl = useMemoryStore((s) => s.redirectTargetUrl);
  const redirectUrlFromStore = redirectTargetUrl?.redirectUrl;

  const { token, redirectUrl } = router.query;

  const { user, isProf, isLyceen, isEclaireur } = useUser();

  if (user) {
    userId = user.id;
    userSsoId = user.ssoId;

    if (isLyceen) {
      profil = 'lyceen';
    } else if (isEclaireur) {
      profil = 'eclaireur';
    } else if (isProf) {
      profil = 'pedagogie';
    } else {
      profil = 'guest';
    }
  }

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    visitorId = getCookie('visitorId');

    let ssoProfileToken = null;
    if (!router.query.sso_profile_token) {
      // Case where the user is not logged in and has no sso_profile_token
      // In this case we want to redirect toward the sso login page
      // And redirect after the sso-login to the current page
      const asPath = router.asPath;

      const origin =
        typeof window !== 'undefined' && window.location.origin
          ? window.location.origin
          : '';

      const currentUrl = signupToken
        ? `${CONNEXION_PATH}?token=${signupToken}`
        : redirectUrlFromStore
        ? `${CONNEXION_PATH}?redirectUrl=${redirectUrlFromStore}`
        : `${origin}${asPath}`;

      accessSSO({
        targetUrl: currentUrl,
      });
    } else {
      // Case where the user is logged in from the sso
      ssoProfileToken = decodeURIComponent(
        String(router.query.sso_profile_token)
      );

      let callbackUrl = token;

      // if there's a signupToken in the store, we redirect the user to the join classe page
      // and pass the token as a query param
      callbackUrl = redirectUrl
        ? `${origin}${redirectUrl}`
        : LOGGED_IN_HOME_PATH;

      const redirect = router?.query?.token
        ? true
        : router?.query?.redirectUrl
        ? true
        : false;

      // const redirect = !!lyceenSignupClasse?.signupToken;
      // const redirect = router?.query?.token ? true : false;

      // Trigger the login
      signIn('credentials', {
        ssoProfileToken,
        callbackUrl,
        redirect,
      })
        .then((response) => {
          // console.log(response.error);
          if (response?.error) {
            console.log('error', response.error);
            const error = response.error;
            if (error === 'CredentialsSignin') {
              wrongCredentialsToast();
            } else if (error) {
              didntWorkToast();
            } else {
              // We need to wait a bit for the user to be logged in
              // before redirecting
              wait(2000).then(() => {
                console.log('wait done');
              });
            }
          } else {
            // No errors

            // we track the user connexion and we send the accountId to Matomo, so data analysists can link the Matomo Id and User ID
            TagManager.dataLayer({
              dataLayer: {
                event: 'sso_connexion',
                eventCategory: GTM_CATEGORY_COMPTE,
                eventAction: GTM_ACTION_CONNEXION_SUCCES,
                eventLabel: undefined,
                eventValue: undefined,
                accountId: userId,
                ssoId: userSsoId,
                visitorId: visitorId,
                profil: profil,
              },
            });
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <Page title="Connexion" withPanel headingIsTitle>
      <Box textAlign="center">
        <Panel compactOnMobile style={{ marginBottom: '1.5rem' }}>
          Encore un peu de patience...
        </Panel>
      </Box>
    </Page>
  );
};

ConnexionPage.getWrappers = (page) => (
  <Layout rowVariant="yellow" containerWidth="sm" showLoginButton={false}>
    <OnlyLoggingOngoing>{page}</OnlyLoggingOngoing>
  </Layout>
);

export default ConnexionPage;
